import store from "@/store/index.js";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  data: () => ({
    isEmailSearchRunning: false,
    lead: {
      old_client_id: null,
      lead_source: "",
      lead_feedback_comment: [
        {
          id: 0,
          is_feedback: 0,
          comment: null,
        },
      ],
      trips: [],
      client: {
        client_emails: [
          {
            comment: null,
            preferred: false,
            value: "",
          },
        ],
        client_phones: [
          {
            prefix: null,
            value: "",
            comment: null,
            preferred: false,
          },
        ],
      },
    },
    options: [
      "Other",
      "My Customer",
      "Personal Call",
      "Personal Email",
      "Email Campaign",
      "Google Call",
      "Facebook Call",
      "Bing Call",
      "Skyscanner Call",
      "Kayak Call",
    ],
    commentText: "",
    commentEmailText: "",
    answer: "",
    airlinesList: [],
    selectedAirline: [],
    searchEmail: "",
    fromDest: {},
    toDest: {},
    countryIti: null,
    error: "",
    backUrl: "/lead",
    disabled: false,
    requestRunning: false,
    preferences: {
      purpose_of_travel: null,
      market_price: null,
      client_budget: null,
      airline_preference: [],
      number_of_stops: null,
      need_hotel: null,
      client_values: {
        urgency: false,
        flat_bed: false,
        flexible_travel_dates: false,
        flight_convenience: false,
        airline_choice: false,
        book_today_for_better_price: false,
      },
    },
    leadId: null,
    selectedPhoneIndex: null,
    comment: "",
    companyList: [],
    modal_phone_comment: false,
    selectedPhoneId: null,
    modal_email_comment: false,
    selectedEmailId: null,
    datePickerFormat: "MMM-DD-YYYY",
    newLeadEmail: "",
  }),
  components: {
    Multiselect,
    DatePicker,
  },

  computed: {
    tripsCopy() {
      return this.lead.trips.slice();
    },
    // user() {
    // return this.$store.state.userDetail;
    // },
    // userType() {
    //   return this.user && this.user.id > 0 ? this.user.roles[0].name : "";
    // },
  },
  watch: {
    companyList: {
      handler(newList) {
        if (newList.length > 0) {
          this.lead.client.company_id = newList[0].id;
        }
      },
      immediate: true,
    },
    "lead.trips": {
      handler(newVal, oldVal) {
        newVal.forEach((trip, index) => {
          if (trip.adult_seat <= 0) {
            this.$set(this.lead.trips, index, { ...trip, adult_seat: 1 });
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    formatPhoneNumber(index) {
      let phoneNumber = this.lead.client.client_phones[index].value;
      phoneNumber = phoneNumber.replace(/\D/g, "");
      this.lead.client.client_phones[index].value = phoneNumber;
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const next14Months = new Date(
        today.getFullYear(),
        today.getMonth() + 14,
        today.getDate()
      );

      return date < today || date >= next14Months;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayTwelveOClock(date) {
      return date < new Date(new Date().setHours(12, 0, 0, 0));
    },
    handleInputClickfromDest(index) {
      const inputElement = document.getElementById(`fromDest_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    handleInputClicktoDest(index) {
      const inputElement = document.getElementById(`toDest_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    selectInputText(inputElement) {
      inputElement.select();
    },
    saveComment() {
      const selectedPhone = this.lead.client.client_phones.find(
        (phone) => phone.id === this.selectedPhoneId
      );
      selectedPhone.comment = this.commentText;

      this.closeModal();
      this.commentText = "";
    },

    saveEmailComment() {
      const selectedEmail = this.lead.client.client_emails.find(
        (email) => email.id === this.selectedEmailId
      );
      selectedEmail.comment = this.commentEmailText;
      this.closeEmailModal();
      this.commentEmailText = "";
    },
    openModal(index) {
      this.selectedPhoneId = this.lead.client.client_phones[index].id;
      this.modal_phone_comment = true;
    },
    closeModal() {
      this.modal_phone_comment = false;
      this.selectedPhoneId = null;
    },
    openEmailModal(index) {
      this.selectedEmailId = this.lead.client.client_emails[index].id;
      this.modal_email_comment = true;
    },
    closeEmailModal() {
      this.modal_email_comment = false;
      this.selectedEmailId = null;
    },

    searchAirport(search, index) {
      if (search.length) {
        let _vm = this;
        this.axios
          .get("search-airport?search_key=" + search)
          .then(function (response) {
            _vm.lead.trips[index].fromAirportList = response.data.data;
          });
      }
    },
    validatePage() {
      let _vm = this;
      let lead_trips = [];
      const airline = [];
      this.selectedAirline.forEach((element) => {
        airline.push(element.code);
      });
      _vm.preferences.airline_preference = airline;

      if (_vm.isEmailSearchRunning || _vm.requestRunning) {
        return;
      }
      _vm.requestRunning = true;
      this.lead.trips.forEach((element, index) => {
        element.from_airport_code =
          element.fromDestSelectedLocation.city_code ||
          element.fromDestSelectedLocation.code;
        element.to_airport_code =
          element.toDestSelectedLocation.city_code ||
          element.toDestSelectedLocation.code;
        lead_trips.push({
          cabin: element.travel_class,
          adults: element.adult_seat,
          children: element.child_seat,
          infants: element.infant_seat,
          date: element.from_date,
          from: {
            country: element.fromDestSelectedLocation.country,
            city: element.fromDestSelectedLocation.city,
            code: element.from_airport_code,
          },
          to: {
            country: element.toDestSelectedLocation.country,
            city: element.toDestSelectedLocation.city,
            code: element.to_airport_code,
          },
        });
      });

      const preferencesData = {
        purpose_of_travel: this.preferences.purpose_of_travel,
        market_price: this.preferences.market_price,
        client_budget: this.preferences.client_budget,
        airline_preference: this.preferences.airline_preference,
        number_of_stops: this.preferences.number_of_stops,
        need_hotel: this.preferences.need_hotel,
        client_values: {},
      };
      const checkboxValues = [
        "book_today_for_better_price",
        "airline_choice",
        "flexible_travel_dates",
        "flight_convenience",
        "urgency",
        "flat_bed",
      ];
      checkboxValues.forEach((value) => {
        preferencesData.client_values[value] =
          this.preferences.client_values[value] || false;
      });
      _vm.lead["preferences"] = preferencesData;

      for (
        let index = 0;
        index < _vm.lead.client?.client_phones?.length;
        index++
      ) {
        _vm.lead.client.client_phones[index]["prefix"] =
          "+" +
          _vm.lead.client.client_phones[index][
            "countryIti"
          ].getSelectedCountryData().dialCode;
      }
      const requestData = {
        ...this.lead,
        trips: lead_trips,
        _method: "PATCH",
      };

      this.axios
        .post("/leads/" + this.lead.id, requestData)
        .then(function (response) {
          window.location.href =
            "/lead/" + JSON.parse(atob(response.data.data)).id;
        })
        .catch(function (error) {
          _vm.requestRunning = false;
        });
    },
    setLeadSource(e) {
      let source = e.target.value;
      if (source === "Return" || source === "Referral") {
        $("#lead_source").val("Return/Referral");
        this.lead.lead_source = $("#lead_source").val();
        $("#lead_source").attr("disabled", "disabled");
      } else {
        $("#lead_source").attr("disabled", false);
      }
    },
    removePhone(id, index) {
      const phone = this.lead.client.client_phones[index];
      if (id || !phone.id) {
        this.lead.client.client_phones.splice(index, 1);
      }
    },

    removeEmail(id, index) {
      const emails = this.lead.client.client_emails[index];
      if (id || !emails.id) {
        this.lead.client.client_emails.splice(index, 1);
      }
    },
    deleteRecord() {
      let _vm = this;
      this.$dialog
        .confirm("Are you sure you want to delete this record?")
        .then(function () {
          _vm.axios
            .delete("/leads/" + _vm.lead.id)
            .then(function () {
              _vm.$router.push(_vm.backUrl);
            })
            .catch(function (error) { });
        })
        .catch(function (error) { });
    },
    removeSegment(index) {
      this.$dialog
        .confirm("Are you sure you want to delete record " + (index + 1) + "?")
        .then(() => {
          this.lead.trips.splice(index, 1);
        })
        .catch((error) => { });
    },

    addNewSegment() {
      let lastElement = this.lead.trips[this.lead.trips.length - 1];
      this.lead.trips.push({
        adult_seat: lastElement ? lastElement.adult_seat : "",
        child_seat: lastElement ? lastElement.child_seat : 0,
        infant_seat: lastElement ? lastElement.infant_seat : 0,
        travel_class: lastElement ? lastElement.travel_class : "Business Class",
        from_date: lastElement ? lastElement.from_date : "",
        lead_id: this.$route.params.id,
        id: 0,
        fromDest: lastElement ? lastElement["toDestSelectedLocation"] : "",
        toDest: lastElement ? lastElement["fromDestSelectedLocation"] : "",
        fromDestSelectedLocation: lastElement
          ? lastElement["toDestSelectedLocation"]
          : "",
        toDestSelectedLocation: lastElement
          ? lastElement["fromDestSelectedLocation"]
          : "",
      });

      let _vm = this;
      this.$nextTick(() => {
        let index = 0;
        if (_vm.lead.trips.length > 0) {
          index = _vm.lead.trips.length - 1;
          let element = _vm.lead.trips[index];
          if (element.fromDest != "") {
            $("#fromDest_" + index).val(
              element.fromDest.city_code + " - " + element.fromDest.city
            );
          }
          if (element.toDest != "") {
            $("#toDest_" + index).val(
              element.toDest.city_code + " - " + element.toDest.city
            );
          }
        }

        _vm.loadAirportLookup(index, "fromDest");
        _vm.loadAirportLookup(index, "toDest");
        let minDate = new Date();
        if (this.lead.trips.length > 1) {
          minDate = new Date(
            $("#departDate_" + (this.lead.trips.length - 2)).val()
          );
          if (!(minDate instanceof Date && !isNaN(minDate))) {
            minDate = new Date();
          }
        }
      });
      this.canRemoveLastSegment = this.lead.trips.length > 1;
    },

    searchClientByEmail(email, index) {
      let _vm = this;
      if (this.searchEmail == email) {
        return;
      }
      store.state.isLoaderShow = true;
      this.searchEmail = email;
      this.isEmailSearchRunning = true;
      this.axios
        .get("/search-client-by-email?email=" + email)
        .then(function (response) {
          let data = JSON.parse(atob(response.data.data));
          store.state.isLoaderShow = false;
          if (data.client && data.client.id > 0) {
            if (data.oldClient) {
              _vm.lead.old_client_id = data.client.id;
              _vm.lead.client.first_name = data.client.first_name;
              _vm.lead.client.middle_name = data.client.middle_name;
              _vm.lead.client.last_name = data.client.last_name;
              let emailsArray = [];
              data.client.old_emails.forEach((e) => {
                let emailObj = {
                  comment: null,
                  preferred: false,
                  value: e.email,
                };
                emailsArray.push(emailObj);
              });
              _vm.lead.client.client_emails = emailsArray;
              let phonesArray = [];
              data.client.old_phones.forEach((p) => {
                let phoneObj = {
                  comment: null,
                  prefix: p.prefix,
                  preferred: false,
                  value: p.phone,
                };
                phonesArray.push(phoneObj);
              });
              _vm.lead.client.client_phones = phonesArray;
            } else {
              _vm.lead.client.id = data.client.id;
              _vm.lead.client.client_emails = data.client.client_emails;
              _vm.lead.client.first_name = data.client.first_name;
              _vm.lead.client.middle_name = data.client.middle_name;
              _vm.lead.client.last_name = data.client.last_name;
              _vm.lead.client.client_phones =
                data.client.client_phones.length > 0
                  ? data.client.client_phones
                  : [{ prefix: null, value: "", comment: null }];
            }
            _vm.$nextTick(() => {
              for (let i = 0; i < _vm.lead.client.client_phones.length; i++) {
                const input = document.querySelector("#contact_number_" + i);
                _vm.lead.client.client_phones[i]["countryIti"] = intlTelInput(
                  input,
                  {
                    separateDialCode: true,
                  }
                );
                _vm.lead.client.client_phones[i]["countryIti"].setNumber(
                  _vm.lead.client.client_phones[i]["prefix"] +
                  _vm.lead.client.client_phones[i]["value"]
                );
              }
            });
          }
          _vm.isEmailSearchRunning = false;
        })
        .catch(function (error) {
          _vm.isEmailSearchRunning = false;
        });
    },
    addNewPhone() {
      this.lead.client.client_phones.push({
        contact_type: "Work",
        value: "",
        comment: "",
      });
      this.$nextTick(() => {
        const index = this.lead.client.client_phones.length - 1;
        const input = document.querySelector("#contact_number_" + index);
        this.lead.client.client_phones[index]["countryIti"] = intlTelInput(
          input,
          {
            separateDialCode: true,
          }
        );
      });
    },
    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    loadAirport: function (id) {
      var self = this;
      $("#" + id)
        .autocomplete({
          source: function (request, response) {
            $.get(
              process.env.VUE_APP_API_URL +
              "search-airport?search_key=" +
              request.term,
              {},
              function (data) {
                response(data.data);
              }
            );
          },
          minLength: 3,
          select: function (event, ui) {
            event.preventDefault();
            var selectedObj = ui.item;
            let index = id.split("_")[1];
            $("#" + id).val(selectedObj.city_code + " - " + selectedObj.city);
            $("#" + id).removeClass("has-error");
            if (id.indexOf("fromDest") > -1) {
              self.$set(self.lead.trips[index], "fromDest", selectedObj);
            }
            if (id.indexOf("toDest") > -1) {
              self.$set(self.lead.trips[index], "toDest", selectedObj);
            }
          },
          focus: function (event, ui) {
            let index = id.split("_")[1];
            if (id.indexOf("fromDest") > -1) {
              self.$set(self.lead.trips[index], "fromDest", ui.item);
            }
            if (id.indexOf("toDest") > -1) {
              self.$set(self.lead.trips[index], "toDest", ui.item);
            }
          },
        })
        .autocomplete("instance")._renderItem = function (ul, item) {
          return $("<li>")
            .append(
              "<div>" +
              item.city +
              ", " +
              item.country +
              "<span class='pull-right'>" +
              item.airport_code +
              "</span><br><span class='clsAirportName'>" +
              item.airport +
              "</span></div>"
            )
            .appendTo(ul);
        };
      $("#" + id).on("click", function () {
        $(this).select();
      });
    },
    generateSearchResult(type, list, listIndex) {
      let html = `<div class="search-result-list">
							<!-- Airport parents list start -->
							<ul class="search-airport">`;
      list.forEach((element, index) => {
        html += `<li>
							<div class="search-airport" @click="selectLocation('${type}',${listIndex},${index})">
								<div>
									<span>${element.city}, ${element.country}</span>
									<p>(All Airports)</p>
								</div>
							</div>
								<!-- Airport child list start -->
								<ul>`;
        element.airports.forEach((airport, subIndex) => {
          html += `<li @click="selectLocation('${type}',${listIndex},${index},${subIndex})">
								<div class="search-airport">
									<span>${airport.airport}</span>
									<p>${airport.city}</p>
								</div>
								<a href="javascript:;">${airport.airport_code}</a>
							</li>`;
        });
        html += ` </ul> </li>`;
      });
      html += `
								</ul>
								<!-- Airport parent list end -->
							</div>`;
      return html;
    },
    selectLocation(type, airport, listIndex, isCity = false) {
      document.getElementById(`${type}_${listIndex}`).value = `${isCity ? airport.city_code : airport.airport_code
        } - ${airport.city}`;
      this.lead.trips[listIndex][`${type}SelectedLocation`] = airport;
      $(`#${type}SearchList_${listIndex}`).removeClass("show");
    },
    loadAirportLookup(id, type) {
      const _vm = this;
      $(`#${type}_${id}`).keyup(function () {
        var query = $(this).val();
        if (query.length > 2) {
          _vm.$set(_vm.lead.trips[id], `${type}Loading`, true);
          $(`#${type}SearchList_${id}`).addClass("show");
          $.ajax({
            url:
              process.env.VUE_APP_API_URL +
              "/search-airport?search_key=" +
              query,
            type: "GET",
            success: function (data) {
              _vm.lead.trips[id][`${type}Location`] = data.data;
              _vm.$set(_vm.lead.trips[id], `${type}Loading`, false);
            },
          });
        }
      });
      $(document).on("click", function (e) {
        if ($(e.target).closest(`#${type}SearchList_${id}`).length === 0) {
          $(`#${type}SearchList_${id}`).removeClass("show");
        }
      });
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
        })
        .catch(function () { });
    },
    getAirlinesCode() {
      let _vm = this;
      const queryString = this.$route.params.id
        ? "?id=" + this.$route.params.id
        : "";
      this.axios
        .get("/airline-list" + queryString, _vm.airlinesList)
        .then(function (response) {
          _vm.airlinesList = response.data.data;
        })
        .catch(function () { });
    },
    customLabel({ name, code }) {
      return `${name} - ${code}`;
    },
  },
  mounted() {
    if (this.$route.query) {
      this.newLeadEmail = this.$route.query.email;
      this.searchClientByEmail(this.newLeadEmail);
      if (window.location.search) {
        history.replaceState({}, document.title, window.location.pathname);
      }
    }
    this.getCompany();
    this.getAirlinesCode();
    this.addNewSegment();
    for (let i = 0; i < this.lead.client.client_phones.length; i++) {
      const input = document.querySelector("#contact_number_" + i);
      this.lead.client.client_phones[i]["countryIti"] = intlTelInput(input, {
        separateDialCode: true,
      });
    }
  },
};
